<template>
	<section class="container-fluid py-5">
		<div class="d-flex flex-column align-items-center">
			<BaseActionButton type="button" class="btn btn-primary mb-4" v-on:on-click="generateRapport" :disabled="isLoading">
				<span v-if="isLoading">
					<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
					Loading...
				</span>
				<span v-else> Lav rapport </span>
			</BaseActionButton>
			<xlsx-workbook v-if="rapportIsReady" class="mb-4">
				<xlsx-sheet :collection="sheet.data" v-for="sheet in getExcelFormattedData" :key="sheet.name" :sheet-name="sheet.name" />
				<xlsx-download :filename="getFileName">
					<b-button class="btn btn-success">Download medarbejderelisten</b-button>
				</xlsx-download>
			</xlsx-workbook>
		</div>
	</section>
</template>
<script>
import axios from 'axios'
import config from '../../../../../conf.json'
import { XlsxWorkbook, XlsxSheet, XlsxDownload } from 'vue-xlsx'

export default {
	name: 'Rapport',
	components: {
		XlsxWorkbook,
		XlsxSheet,
		XlsxDownload,
	},
	data() {
		return {
			isReady: false,
			rapportIsReady: false,
			csvArray: [],
			isLoading: false,
			transferArray: [],
		}
	},
	created() {
		this.$emit('updateLoader')
	},
	methods: {
		async generateRapport() {
			this.isLoading = true
			// Call the API to generate the rapport
			let idToken = await this.$store.getters['auth/idToken']

			var options = {
				url: config.firebaseConfig.apiURL + '/integration/csv',
				method: 'GET',
				headers: {
					Authorization: 'Bearer ' + idToken,
				},
			}
			var result = await axios(options)
			this.isLoading = false
			// Download the file as csv
			console.log(result.data)

			this.csvArray = result.data
			// Remove all null from array
			this.csvArray = this.csvArray.filter((el) => {
				return el != null
			})
			var transferArray = []
			var custodianValueArray = []
			this.csvArray.forEach((element) => {
				transferArray.push(element["Overførsel"])
				custodianValueArray.push(element["Depotværdi"])
				delete element["Overførsel"]
				delete element["Depotværdi"]
			})

			// Flat the array
			this.transferArray = transferArray.flat()
			this.custodianValueArray = custodianValueArray.flat()
			this.rapportIsReady = true
		},
	},
	computed: {
		getFileName() {
			return 'Medarbejderliste.xlsx'
		},
		getExcelFormattedData() {
			let sheet = [
				{
					name: 'Medarbejderliste',
					data: this.csvArray,
				},
				{
					name: 'Overførsler',
					data: this.transferArray,
				},
				{
					name: 'Depotværdi',
					data: this.custodianValueArray,
				},
			]

			return sheet
		},
	},
}
</script>

<style></style>
